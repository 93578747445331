import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoginView from "./routes/loginView";
import DashboardView from "./routes/dashboardView";
import DashboardPetView from "./routes/dashboardPetView";
import PublicPetView from "./routes/publicPetView";
import SignOutView from "./routes/signOutView";
import RegisterView from "./routes/registerView";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="login" element={<LoginView />} />
      <Route path="dashboard" element={<DashboardView />} />
      <Route path="register" element={<RegisterView />} />
      <Route path="signout" element={<SignOutView />} />
      <Route path="dashboard/pet" element={<DashboardPetView />} />
      <Route path="p/:petid" element={<PublicPetView />} />
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
