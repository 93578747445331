// @ts-nocheck

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import { ListItemButton } from "@mui/material";
import PetsIcon from "@mui/icons-material/Pets";
import IconButton from "@mui/material/IconButton";

export default function PetList({ petList }) {
  return (
    <div>
      <List
        dense
        sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        style={{ minWidth: "300px" }}
      >
        {petList.map((pet: any, index) => (
          <ListItem
            key={index}
            disablePadding
            secondaryAction={
              <IconButton edge="end" aria-label="comments">
                <PetsIcon />
              </IconButton>
            }
          >
            <ListItemButton>
              <ListItemAvatar>
                <Avatar>{pet.Name.substring(0, 2).toUpperCase()}</Avatar>
              </ListItemAvatar>
              <ListItemText id={index} primary={`${pet.Name}`} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );
}
