// @ts-nocheck
import AuthProvider from "../components/authProvider";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { existUsername, updateUser } from "../firebase/firebase";

function RegisterView() {
  const navigate = useNavigate();
  const [state, setState] = useState(0);
  const [currentUser, setCurrentUser] = useState(null);

  function handleUserLoggedIn(user: any) {
    navigate("/dashboard");
  }
  function handleUserNotRegistered(user: any) {
    setCurrentUser(user);

    setState(3);
  }
  function handleUserNotLoggedIn() {
    navigate("/login");
  }
  async function handleContinue() {
    if (currentUser.username !== "") {
      const exists = await existUsername(currentUser.username);
      if (exists) {
        setState(5);
      } else {
        const tmp = currentUser;
        tmp.processCompleted = true;

        await updateUser(tmp);
        setState(6);
      }
    }
  }
  function handleInputUserName(e) {
    const tmpUser = currentUser;
    const value = e.target.value;
    tmpUser.username = value;
    setCurrentUser({ ...tmpUser });
  }
  if (state === 3 || state === 5) {
    return (
      <div>
        <h1>Bienvenido {currentUser.displayName}!</h1>
        <p>Elige tu nombre de usuario:</p>
        {state === 5 ? <p>El nombre de usuario ya existe, escoge otro</p> : ""}
        <div>
          <input type="text" onInput={handleInputUserName} />
        </div>
        <div>
          <button onClick={handleContinue}>Continue</button>
        </div>
      </div>
    );
  }
  if (state === 6) {
    return (
      <div>
        <h1>Congrats, you can now go to dashboard</h1>
        <Link to="/dashboard">Continue</Link>
      </div>
    );
  }
  return (
    <AuthProvider
      onUserLoggedIn={handleUserLoggedIn}
      onUserNotRegistered={handleUserNotRegistered}
      onUserNotLoggedIn={handleUserNotLoggedIn}
    >
      <div>Loading...</div>
    </AuthProvider>
  );
}

export default RegisterView;
