import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  getBytes,
} from "firebase/storage";
import {
  getFirestore,
  collection,
  addDoc,
  getDocs,
  doc,
  getDoc,
  query,
  where,
  setDoc,
  deleteDoc,
  DocumentData,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
};

export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export async function userExists(uid: string) {
  const docRef = doc(db, "users", uid);
  const res = await getDoc(docRef);
  console.log(res);
  return res.exists();
}
export async function existUsername(username: string) {
  const users: any = [];
  const docsRef = collection(db, "users");
  const q = query(docsRef, where("username", "==", username));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    users.push(doc.data());
  });
  return users.length > 0 ? users[0].uid : null;
}

export async function registerNewUser(user: any) {
  try {
    const collectionRef = collection(db, "users");
    const docRef = doc(collectionRef, user.uid);
    await setDoc(docRef, user);
  } catch (error) {
    console.error(error);
    console.log(user);
  }
}

export async function updateUser(user: any) {
  try {
    const collectionRef = collection(db, "users");
    const docRef = doc(collectionRef, user.uid);
    await setDoc(docRef, user);
  } catch (error) {
    console.error(error);
  }
}

export async function getUserInfo(uid: string) {
  try {
    const docRef = doc(db, "users", uid);
    const document = await getDoc(docRef);
    return document.data();
  } catch (error) {
    console.error(error);
  }
}
export async function registerPet(pet: any) {
  try {
    debugger;
    const docRef = collection(db, "pets");
    const res = await addDoc(docRef, pet);
    return res;
  } catch (error) {
    console.error(error);
  }
}
export async function getMyPets(userid: string) {
  const myPets: any = [];
  try {
    const collectionRef = collection(db, "pets");
    const q = query(collectionRef, where("uid", "==", userid));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      myPets.push(doc.data());
    });
    return myPets;
  } catch (error) {
    console.error(error);
  }
}

export async function petExistFirebase(petTag: string) {
  try {
    const pet: any = [];
    const docsRef = collection(db, "pets");
    const q = query(docsRef, where("Tag", "==", petTag));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      pet.push(doc.data());
    });

    return pet.length > 0 ? pet[0] : null;
  } catch (error) {
    console.error(error);
  }
}
