// @ts-nocheck
import AuthProvider from "../components/authProvider";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import DashboardWrapper from "../components/dashboardWrapper";
import PetList from "../components/petListComp";
import { getMyPets, registerPet } from "../firebase/firebase";
import "../styles/styleDashboard.css";
import Skeleton from "@mui/material/Skeleton";
import { Button } from "@mui/material";
import PetsIcon from "@mui/icons-material/Pets";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import dayjs, { Dayjs } from "dayjs";

function DashboardView() {
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(null);
  const [petList, setPetList] = useState([]);
  const [state, setState] = useState(0);
  const [open, setOpen] = useState(false);
  const [petObject, setPetObject] = useState({
    Name: "",
    DateOfBirth: null,
    Gender: "",
    Breed: "",
    uid: "",
    Tag: "",
    Public: false,
  });
  useEffect(() => {
    if (currentUser !== null) {
      getmyPets();
      async function getmyPets() {
        let myPets = await getMyPets(currentUser.uid);
        setPetList(myPets);
      }
      setPetObject({ ...petObject, uid: currentUser.uid });
    }
  }, [state, currentUser]);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  function handleUserLoggedIn(user: any) {
    setCurrentUser(user);
    setState(2);
  }
  function handleUserNotRegistered(user: any) {
    navigate("/login");
  }
  function handleUserNotLoggedIn() {
    navigate("/login");
  }
  function camelize(str) {
    const str2 = str.charAt(0).toUpperCase() + str.slice(1);
    return str2;
  }

  if (state === 0) {
    return (
      <AuthProvider
        onUserLoggedIn={handleUserLoggedIn}
        onUserNotRegistered={handleUserNotRegistered}
        onUserNotLoggedIn={handleUserNotLoggedIn}
      >
        <div>Loading...</div>
      </AuthProvider>
    );
  }
  return (
    <div>
      <DashboardWrapper>
        <div>
          <h1>
            Hi{" "}
            {currentUser ? camelize(currentUser.displayName.split(" ")[0]) : ""}
            !
          </h1>
          <div className="listHeader">
            <h2>My pets</h2>
            <Button
              variant="contained"
              size="small"
              style={{ height: "30px" }}
              endIcon={<PetsIcon />}
              onClick={handleClickOpen}
            >
              Add
            </Button>
          </div>

          <div className="petListContainer">
            {petList.length > 0 ? (
              <PetList petList={petList} />
            ) : (
              <div style={{ width: "360px" }}>
                <Skeleton animation="wave" height={40} />
                <Skeleton animation="wave" height={40} />
                <Skeleton animation="wave" height={40} />
              </div>
            )}
          </div>
          <Dialog open={open} onClose={handleClose} fullWidth>
            <DialogTitle>Add your pet</DialogTitle>
            <DialogContent>
              <DialogContentText>Tell us more about your pet</DialogContentText>
              <label className="labelForms">Name:</label>
              <TextField
                autoFocus
                margin="dense"
                id="petName"
                type="text"
                variant="outlined"
                fullWidth
                onChange={(e) => {
                  setPetObject({ ...petObject, Name: e.target.value });
                }}
              />
              <label className="labelForms">Date of birth:</label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className="datePickerPetAge"
                  value={petObject.DateOfBirth}
                  onChange={(newValue) => {
                    setPetObject({
                      ...petObject,
                      DateOfBirth: newValue.valueOf(),
                    });
                    console.log(newValue.valueOf());
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              <label className="labelForms">Gender:</label>
              <Select
                fullWidth
                value={petObject.Gender}
                onChange={(e) => {
                  setPetObject({ ...petObject, Gender: e.target.value });
                }}
              >
                <MenuItem value={"Female"}>Female</MenuItem>
                <MenuItem value={"Male"}>Male</MenuItem>
              </Select>
              <label className="labelForms">Breed:</label>
              <TextField
                autoFocus
                margin="dense"
                id="petBreed"
                type="text"
                variant="outlined"
                fullWidth
                onChange={(e) => {
                  setPetObject({ ...petObject, Breed: e.target.value });
                }}
              />
              <label className="labelForms">Tag ID:</label>
              <TextField
                autoFocus
                margin="dense"
                id="tagID"
                type="text"
                variant="outlined"
                fullWidth
                onChange={(e) => {
                  setPetObject({ ...petObject, Tag: e.target.value });
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button
                onClick={() => {
                  setPetObject({ ...petObject, uid: currentUser.uid });
                  registerPet(petObject).then(getMyPets(), handleClose());
                }}
              >
                Register
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </DashboardWrapper>
    </div>
  );
}

export default DashboardView;
