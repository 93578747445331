import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DashboardWrapper from "../components/dashboardWrapper";
import { petExistFirebase } from "../firebase/firebase";
import "../styles/styleDashboard.css";
import dayjs, { Dayjs } from "dayjs";

function PublicPetView() {
  const params = useParams();
  const [profile, setProfile] = useState(null);
  const [petObject, setPetObject] = useState({
    Name: "",
    DateOfBirth: null,
    Gender: "",
    Breed: "",
    uid: "",
    Tag: "",
    Public: false,
  });
  useEffect(() => {
    getPet();
    async function getPet() {
      const tagID = params.petid;

      try {
        const petRecordFirebase = await petExistFirebase(tagID!);
        if (petRecordFirebase) {
          setPetObject({ ...petRecordFirebase });
        }
      } catch (error) {
        console.error(error);
      }
    }
  }, [params]);
  return (
    <div style={{ backgroundColor: "#f0f1f3", height: "100vh" }}>
      <DashboardWrapper>
        <div className="publicPetView">
          <img
            className="petPicture"
            src="https://www.dogbible.com/_ipx/f_png,q_80,fit_cover,s_1536x1536/dogbible/i/es/cabeza-de-aguila.png"
          />

          <h1>{petObject.Name}</h1>
          <h3>{petObject.Breed}</h3>
          <h3>{dayjs().diff(petObject.DateOfBirth, "month") / 12} years</h3>
        </div>
      </DashboardWrapper>
    </div>
  );
}

export default PublicPetView;
