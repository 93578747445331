import {
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithPopup,
} from "firebase/auth";
import { useState } from "react";
import { auth } from "../firebase/firebase";
import { useNavigate } from "react-router-dom";
import AuthProvider from "../components/authProvider";

export default function LoginView() {
  const navigate = useNavigate();
  //const [currentUser, setCurrentUSer] = useState(null);
  /*
  State
  0: initialize
  1:loading
  2: login completed
  3:login completed without register
  4:no one logged
  5:username already exists
  6:nuevo username, click para continuar
  */
  const [state, setCurrentState] = useState(0);
  /*useEffect(() => {
    setCurrentState(1);
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const isRegistered = await userExists(user.uid);
        if (isRegistered) {
          //Redirect to dashboard
          navigate("/dashboard");
          setCurrentState(2);
        } else {
          // Redirect to register
          navigate("/register");
          setCurrentState(3);
        }
      } else {
        setCurrentState(4);
        console.log("No one logged");
      }
    });
  }, [navigate]);*/

  async function handleOnClick() {
    const googleProvider = new GoogleAuthProvider();
    await signInWithGoogle(googleProvider);
    async function signInWithGoogle(googleProvider: any) {
      try {
        const res = await signInWithPopup(auth, googleProvider);
        console.log(res);
      } catch (error) {
        console.error(error);
      }
    }
  }
  function handleUserLoggedIn(user: any) {
    navigate("/dashboard");
  }
  function handleUserNotRegistered(user: any) {
    navigate("/register");
  }
  function handleUserNotLoggedIn() {
    setCurrentState(4);
  }

  if (state === 4) {
    return (
      <div>
        <button onClick={handleOnClick}>Login with Google</button>
      </div>
    );
  }
  if (state === 5) {
    return (
      <div>
        <button onClick={handleOnClick}>Login with Google</button>
      </div>
    );
  }

  return (
    <AuthProvider
      onUserLoggedIn={handleUserLoggedIn}
      onUserNotRegistered={handleUserNotRegistered}
      onUserNotLoggedIn={handleUserNotLoggedIn}
    >
      <div>Loading...</div>
    </AuthProvider>
  );
}
