// @ts-nocheck
import AppBarDashboard from "./appBarDashboard";
import "../styles/styleDashboard.css";

export default function DashboardWrapper({ children }) {
  return (
    <div>
      <AppBarDashboard />
      <div className="wrapperStyle">{children}</div>
    </div>
  );
}
